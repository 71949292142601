import React, { useEffect } from "react";
import classNames from "classnames";
import MiniSearch from "minisearch";
import { Icon } from "@atoms";

const Search = ({
  className: _className,
  placeholder,
  content,
  queryState,
  noBorder,
  resultState,
  fields,
}) => {
  const [query, setQuery] = queryState;
  // eslint-disable-next-line no-unused-vars
  const [results, setResults] = resultState;

  const miniSearch = new MiniSearch({
    fields: fields || Object.keys(content[0] || []), // fields to index for full-text search
    // storeFields: ["title", "category"], // fields to return with search results
    searchOptions: {
      boost: { name: 1 },
      fuzzy: 0.2,
      prefix: true,
    },
  });

  miniSearch.addAll(content);

  useEffect(() => {
    if (query && query.length) {
      const _results = miniSearch.search(query);
      setResults(_results.map(r => content.find(c => c.id === r.id)));
    } else {
      setResults(content);
    }
  }, [query, content]);

  return (
    <div className={classNames("relative h-full", _className)}>
      <input
        type="text"
        placeholder={placeholder}
        onChange={e => {
          setQuery(e.currentTarget.value);
        }}
        className={classNames("w-full py-1 pr-6 placeholder-gray-dark", {
          "border-b-2 border-black": !noBorder,
        })}
      />
      <div className="absolute inset-y-0 right-0 h-4 w-4 pt-1 text-black">
        <Icon name="search" className="h-full w-full" />
      </div>
    </div>
  );
};

Search.defaultProps = {
  placeholder: "Search",
};

export default Search;
